const disableLogs = () => {
  if (process.env.REACT_APP_NODE_ENV === "production") {
    console.log("disabling");
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
  }
};

export default disableLogs;

import React, { useState, useRef, useEffect } from 'react';
import './growShrinkTooltip.css'; // Custom CSS file for tooltip animation

const GrowShrinkTooltip = ({ message, children, position = 'bottom' }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState(position);
    const timeoutRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (showTooltip) {
            const container = containerRef.current;
            const tooltip = container.querySelector('.tooltip');
            const rect = container.getBoundingClientRect();

            const spaceTop = rect.top;
            const spaceBottom = window.innerHeight - rect.bottom;
            const spaceLeft = rect.left;
            const spaceRight = window.innerWidth - rect.right;

            let newPosition = position;

            console.log('rect', rect, 'position', position);

            if (position === 'bottom') {
                if (spaceBottom < tooltip.offsetHeight + 10) {
                    newPosition = 'top';
                } else if (spaceLeft < tooltip.offsetWidth / 2) {
                    newPosition = 'right';
                } else if (spaceRight < tooltip.offsetWidth / 2) {
                    newPosition = 'left';
                }
            } else if (position === 'top') {
                if (spaceTop < tooltip.offsetHeight + 10) {
                    newPosition = 'bottom';
                } else if (spaceLeft < tooltip.offsetWidth / 2) {
                    newPosition = 'right';
                } else if (spaceRight < tooltip.offsetWidth / 2) {
                    newPosition = 'left';
                }
            } else if (position === 'right') {
                if (spaceRight < tooltip.offsetWidth + 10) {
                    newPosition = 'left';
                } else if (spaceTop < tooltip.offsetHeight / 2) {
                    newPosition = 'bottom';
                } else if (spaceBottom < tooltip.offsetHeight / 2) {
                    newPosition = 'top';
                }
            } else if (position === 'left') {
                if (spaceLeft < tooltip.offsetWidth + 10) {
                    newPosition = 'right';
                } else if (spaceTop < tooltip.offsetHeight / 2) {
                    newPosition = 'bottom';
                } else if (spaceBottom < tooltip.offsetHeight / 2) {
                    newPosition = 'top';
                }
            }

            setTooltipPosition(newPosition);
        }
    }, [showTooltip, position]);

    const handleMouseEnter = () => {
        timeoutRef.current = setTimeout(() => {
            setShowTooltip(true);
        }, 1000);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        setShowTooltip(false);
    };

    return (
        <div className="tooltip-container" ref={containerRef}>
            <div
                className="tooltip-trigger"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
            <div
                className={`tooltip tooltip-${tooltipPosition} ${showTooltip ? 'show' : ''}`}
                style={{ pointerEvents: showTooltip ? 'none' : 'auto' }}
            >
                <div className="tooltip-arrow" />
                {message}
            </div>
        </div>
    );
};

export default GrowShrinkTooltip;

import {useEffect, useState} from "react";

const useCurrentHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
    }, []);
    return height + 'px';
};

export default useCurrentHeight;
import "./App.css";
import 'react-loading-skeleton/dist/skeleton.css';
import React from "react";
import netowrk from "./assets/images/new-loader.gif";
import arrow from "./assets/images/arrow.svg";
import refresh from "./assets/images/refresh.svg";
import logo from "./assets/images/logo.png";
import brand from "./assets/images/middle_brand.svg";
import like from "./assets/images/like.svg";
import unlike from "./assets/images/unlike.svg";
import placeholder from "./assets/images/placeholder.svg";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import {extractLastCompleteObject, parseMetadata, separateObjects} from "./utils/Utils";

function App() {
    const [userMessages, setUserMessages] = React.useState([]);
    const [showChat, setShowChat] = React.useState(true);
    const [showSources, setShowSources] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [enalbeAsking, setEnableAsking] = React.useState(true);
    const [fetchingApi, setFetchingApi] = React.useState(false);
    let newRasWourcesApi = [];
    const [rawSources, setRawSource] = React.useState([]);
    const [prompts, setPrompts] = React.useState([{
        id: 1,
        query: "How to make the most important decisions in your life ?"
    }, {id: 2, query: "What is the state of the economy?"}, {id: 3, query: "How to live asset light?"}, {
        id: 4,
        query: "Is technology going to solve the world's problems?"
    }, {id: 5, query: "What does FJ Lab invest in ?"}, {
        id: 6,
        query: "What is the biggest mistake marketplace founders make ?"
    },]);
    const [newMsg, setNewMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [msgs, setMsgs] = React.useState([]);



    const [sourcesLoading, setSourcesLoading] = React.useState(false);
    React.useEffect(() => {
        if (sourcesLoading) {
        } else {
        }
    }, [sourcesLoading]);
    const [unicornSources, setUnicornSources] = React.useState([]);

    function getUnicornSources(keys, metadata) {
        console.log("unicorn ...", "metadata", metadata);
        let repeatedLinks = [];
        for (let i = 0; i < keys.length; i++) {
            if (!metadata[keys[i]].link) {
                continue;
            }
            if (!repeatedLinks.includes(metadata[keys[i]].link)) {
                const newCunironData = {
                    img: placeholder, link: metadata[keys[i]].link, title: metadata[keys[i]].title
                    /*.split(".com/")[1]
                    .split("-")
                    .join(" ")
                    .split("/")[0]*/,
                };
                console.log("unicorn obj=", newCunironData);
                setUnicornSources((prev) => [...prev, newCunironData]);
                repeatedLinks.push(metadata[keys[i]].link);
            }
        }

        // setSourcesLoading((prev) => false);
    }

    React.useEffect(() => {
        console.log("unicornSources>>", unicornSources);
    }, [unicornSources]);
    const showUnicorn = unicornSources.map((item) => {
        if (item.link && item.title) {
            return (<>
                <a
                    className="unicorn-link"
                    target="_blank"
                    href={item.link}
                    style={{display: "flex", alignItems: "center", gap: "15px"}}
                >
                    <LazyLoadImage
                        style={{
                            height: '80px', width: '103px', margin: '0px'
                        }}
                        alt={"placeholder"}
                        effect={'blur'}
                        src={item.img ?? placeholder}/>
                    <p>{item.title}</p>
                </a>
                <div className="horizontal-divider"></div>
            </>);
        }
    });

    async function getMetaData(metadata) {
        console.log("1st>> ", metadata);
        setSourcesLoading((prev) => true);
        let sourceIds = [];
        let newSources = [];
        await setRawSource((prev) => []);
        await setUnicornSources((prev) => []);
        console.log("prev: ", rawSources);
        const keys = Object.keys(metadata);
        for (let i = 0; i < keys.length; i++) {
            console.log("metadata[keys[i]]>>> ", metadata[keys[i]], Object.keys(metadata[keys[i]]).length);
            if (Object.keys(metadata[keys[i]]).length === 0) {
                continue;
            }
            if (!("id" in metadata[keys[i]])) {
                getUnicornSources(keys, metadata);
                return;
            }
            console.log("compare: ", sourceIds, metadata[keys[i]].id, sourceIds.includes(metadata[keys[i]].id));
            if (!("title" in metadata[keys[i]])) {
                continue;
            }
            const title = metadata[keys[i]].title.rendered;
            if (sourceIds.includes(metadata[keys[i]].id)) {
                continue;
            } else {
                sourceIds.push(metadata[keys[i]].id);
            }
            const url = metadata[keys[i]]._links["wp:featuredmedia"][0].href;
            const modified = metadata[keys[i]].date;
            const [date, time] = modified.split("T");
            try {
                const response = await fetch(url);
                const allData = await response.json();
                const guid = allData.guid.rendered;
                const link = metadata[keys[i]].link;
                const newRawSource = {
                    id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                    img: guid,
                    header: title,
                    body: "Fabrice",
                    link: link,
                    date: date,
                };
                newSources.push(newRawSource);
                /*
                setRawSource((prev) => {
                  return [...prev, newRawSource].sort(compareByDate)
                });*/
            } catch (err) {
                console.log("some err at api hapened ", err); /*
        const link = metadata[keys[i]].link;
        const newRawSource = {
          id: Date.now().toString(36) + Math.random().toString(36).substr(2),
          img: url,
          header: title||metadata[keys[i]].title,
          body: "Fabrice",
          link: link,
          date: date,
        };
        newSources.push(newRawSource);*/
            }
        }
        setRawSource((prev) => newSources);
        setSourcesLoading((prev) => false);
    }

    const fetchDataStreamApi = async (query = "How to live asset light?") => {
        //let testArray = []
        let fullObject = "";
        let fullResponse = "";
        // setIsTypingTrue();
        setFetchingApi((prev) => true);
        try {
            setLoading(true);
            const response = await fetch("https://openai-api-dev.fabricegrinda.com/stream", {
                method: "POST", headers: {
                    "Content-Type": "application/json",
                }, body: JSON.stringify({query: query}), // body data type must match "Content-Type" header
            });
            if (!response.ok || !response.body) {
                setFetchingApi((prev) => false);
                throw response.statusText;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            setFetchingApi((prev) => false);
            setUserMessages((prev) => {
                return [...prev, {
                    id: Date.now().toString(36) + Math.random().toString(36).substr(2), from: "app", content: "",
                },];
            });

            while (true) {
                const {value, done} = await reader.read();
                if (done) {
                    setLoading(false);
                    break;
                }

                const decodedChunk = decoder.decode(value, {stream: true});
                fullObject += decodedChunk;
                let metadata = "";
                let obj = "";
                try {
                    obj = JSON.parse(decodedChunk);
                    setNewMsg((prev) => `${prev}${obj.response}`);
                    // console.log("fixed1", obj);
                    // if (obj.metadata) {
                    //   console.log("obj.metadata ", obj.metadata);
                    //   getMetaData(obj.metadata);
                    // }
                } catch (err) {
                    const objects = separateObjects(decodedChunk);
                    //testArray.push(objects)
                    // console.log("fixed2", objects);
                    objects.forEach((res) => {
                        //console.log("fixed3=>",res)
                        //console.log("fixed3=>keys->res",Object.keys(res).includes('response'))
                        if (res.response !== undefined) {
                            // console.log("()res", res);
                            fullResponse += res.response;
                            setNewMsg((prev) => `${prev}${res.response}`);
                        }
                        /*if (res.metadata !== undefined) {
                          console.log("2nd>> ", res.metadata);
                          metadata = res.metadata;
                          let sourceTitles = [];
                          setRawSource((prev) => {
                            return [];
                          });
                          Object.keys(metadata).forEach(async (key) => {
                            const title = metadata[key].title.rendered;
                            if (sourceTitles.includes(title)) {
                              return;
                            } else {
                              sourceTitles.push(title);
                            }
                            const url = metadata[key]._links["wp:featuredmedia"][0].href;
                            const modified = metadata[key].date;
                            const [date, time] = modified.split("T");
                            //console.log("date , time",date,time)
                            const response = await fetch(url);
                            const allData = await response.json();
                            const guid = allData.guid.rendered;
                            const link = allData.link;
                            const newRawSource = {
                              id:
                                Date.now().toString(36) +
                                Math.random().toString(36).substr(2),
                              img: guid,
                              header: title,
                              body: "Fabrice",
                              link: link,
                              date: date,
                            };
                            //console.log('newRawSource = ',newRawSource)
                            //console.log('rawSources = ',rawSources)
                            setRawSource((prev) => {
                              return [...prev, newRawSource].sort(compareByDate);
                            });
                          });
                        }*/
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            setIsTypingFalse();
            console.log("api error : ", error);
            setFetchingApi((prev) => false);
            handleNewMsg("api", "Server timeout , please try again.");
            return;
        }
        // getMetaData(separateObjects(fullObject)[0].metadata);
        console.log("fullResponse", fullResponse)
        // setUserMessages((prev) => {
        //   return [
        //     ...prev.slice(0, prev.length - 1),
        //     {
        //       id: Date.now().toString(36) + Math.random().toString(36).substr(2),
        //       from: "app",
        //       content: fullResponse,
        //     },
        //   ];
        // });
        setNewMsg((prev) => "");
        setIsTypingFalse();
    };

    const fetchMetaDataApi = async (query = "How to take important decisions in life?") => {
        // console.log('started loading sources');
        setSourcesLoading(true);
        setUnicornSources([]);
        const response = await fetch("https://openai-api-dev.fabricegrinda.com/metadataStream", {
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify({query: query}), // body data type must match "Content-Type" header
        });
        if (!response.ok || !response.body) {
            setFetchingApi((prev) => false);
            throw response.statusText;
        }
        try {
            setShowSources(true);
            // const parsed = await response.json();
            // console.log("response metadata", parsed);
            //
            // // getMetaData(parsed.metadata);
            // await parseMetadata(parsed.metadata);
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';
            let lastCompleteObject = {};
            while (true) {
                const {done, value} = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value, {stream: true});
                buffer += separateObjects(chunk).filter(v => v.response !== '```' && v.response !== 'json').map(value => value.response).join('');
                // if(extractLastCompleteObject(buffer)?._links?.["wp:featuredmedia"]) {
                //   // console.log('lastCompleteObject', lastCompleteObject);
                //   // await parseMetadata([lastCompleteObject]);
                //   lastCompleteObject = extractLastCompleteObject(buffer);
                // }
                if (extractLastCompleteObject(buffer) !== null && extractLastCompleteObject(buffer)?.title !== lastCompleteObject.title) {
                    console.log('extractLastCompleteObject', extractLastCompleteObject(buffer));
                    const parsedMetadata = await parseMetadata([extractLastCompleteObject(buffer)]);
                    setUnicornSources(prevState => {
                        const updated = [...prevState, parsedMetadata[0]];
                        return updated;
                    });
                    lastCompleteObject = extractLastCompleteObject(buffer);
                }


            }

            // const metadataResponse = JSON.parse(buffer);
            console.log('buffer', buffer);

        } catch (e) {
            console.log('Metadata', e);
        } finally {
            setSourcesLoading(false);
        }
    }



    // function extractLastCompleteObject(jsonString) {
    //   try {
    //     const updatedString = jsonString.split('{\n' +
    //         '  "metadata": [\n' +
    //         '    ')[1];
    //     const extractedObjects = updatedString.split(
    //         '    {\n' +
    //         '      "link":');
    //     let lastCompleteObject = undefined;
    //     console.log('extractedObjects', extractedObjects);
    //     // extractedObjects.forEach((value, index) => {
    //     //   const updatedValue = index === 0 ? value + "}" : '' + value;
    //     //   try {
    //     //     lastCompleteObject = JSON.parse(updatedValue);
    //     //   } catch (e) {
    //     //     console.log("error", updatedValue)
    //     //   }
    //     // });
    //     return lastCompleteObject;
    //
    //   } catch (e) {
    //     return null;
    //   }
    // }




    React.useEffect(() => {
        if (loading) {
            setMsgs((prev) => {
                return [...prev.slice(0, msgs.length - 1), newMsg];
            });
            if (userMessages.length > 0 && userMessages[userMessages.length - 1].from === "app") {
                setUserMessages((prev) => {
                    return [...prev.slice(0, prev.length - 1), {
                        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                        from: "app",
                        content: newMsg,
                    },];
                });
            } else {
                setUserMessages((prev) => {
                    return [...prev, {
                        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                        from: "app",
                        content: newMsg,
                    },];
                });
            }
        }
        setTimeout(() => {
            document.getElementsByClassName("conversation-window")[0].scrollTop = document.getElementsByClassName("conversation-window")[0].scrollHeight;
        }, 100);
    }, [newMsg]);

    function compareByDate(obj1, obj2) {
        return new Date(obj2.date) - new Date(obj1.date);
    }

    //  React.useEffect(()=>{
    //    newRasWourcesApi = rawSources.map((source) => {
    //      return (<a target="_blank" className='source-link' href={source.link} key={source.id}>
    //        <div className='sources'>
    //          <div className='source-content'>
    //            <img className='source-img' src={source.img||'https://pic.onlinewebfonts.com/thumbnails/icons_148071.svg'} alt={source.header} />
    //            <div className='source-header-body'>
    //              <h3 className='source-header'>{source.header}</h3>
    //              <p className='source-body'>{source.body} {source.date}</p>
    //            </div>
    //          </div>
    //          <div className='horizontal-divider'></div>
    //        </div>
    //      </a>
    //      )

    //    })
    //  },[rawSources])

    const [sortedSources, setSortedSources] = React.useState([]); // updated code for sorting sources as per Date
    React.useEffect(() => {
        const sortedSources = rawSources
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((source) => {
                return (<a
                    target="_blank"
                    className="source-link"
                    href={source.link}
                    key={source.id}
                >
                    <div className="sources">
                        <div className="source-content">
                            <img
                                className="source-img"
                                src={source.img || "https://pic.onlinewebfonts.com/thumbnails/icons_148071.svg"}
                                alt={source.header}
                            />
                            <div className="source-header-body">
                                <h3 className="source-header">{source.header}</h3>
                                <p className="source-body">
                                    {source.body} {source.date}
                                </p>
                            </div>
                        </div>
                        <div className="horizontal-divider"></div>
                    </div>
                </a>);
            });
        setSortedSources(sortedSources);
    }, [rawSources]);

    function setIsTypingTrue() {
        setShowSources((prev) => false);
    }

    function setIsTypingFalse() {
        setShowSources((prev) => true);
    }

    const showConversation = userMessages.map((msg) => {
        if (msg.from === "user") {
            return (<div className="user" key={msg.id}>
                <div className="yellow-circle"></div>
                <p className="msg-user">{msg.content}</p>
            </div>);
        } else {
            return (<div className="app" key={msg.id}>
                <div className="green-square"></div>
                <div className="text-and-icons">
                    <div className="msg-app">
                        <Markdown>
                            {msg.content}
                        </Markdown>
                        {/*<div*/}
                        {/*  dangerouslySetInnerHTML={{*/}
                        {/*    __html: msg.content.replace(/(\r\n|\r|\n)/g, "<br>"),*/}
                        {/*  }}*/}
                        {/*/>*/}
                    </div>

                    {showSources && (<div className="like-icons mt-4">
                        <img src={like} alt="like icon"/>
                        <img src={unlike} alt="dislike icon"/>
                    </div>)}
                </div>
            </div>);
        }
    });

    const sources = rawSources.map((source) => {
        return (<a
            target="_blank"
            className="source-link"
            href={source.link}
            key={source.id}
        >
            <div className="sources">
                <div className="source-content">
                    <img className="source-img" src={source.img} alt={source.header}/>
                    <div className="source-header-body">
                        {/*<h3 className="source-header">{source.header.substr(0,50)} {source.header.length > 30 ? '...':''}</h3>*/}
                        <h3 className="source-header">{source.header}</h3>
                        <p className="source-body">
                            {source.body} {source.date}
                        </p>
                    </div>
                </div>
                <div className="horizontal-divider"></div>
            </div>
        </a>);
    });

    function resetAll() {
        setUserMessages([]);
        setShowChat(true);
        setShowSources(false);
        setError(null);
        setEnableAsking(true);
    }

    function handleNewMsg(from, content) {
        const newMsgContent = {
            id: Date.now().toString(36) + Math.random().toString(36).substr(2), from: from, content: content,
        };
        setUserMessages((prev) => {
            return [...prev, newMsgContent];
        });
        if (userMessages.length > 6) {
            document.getElementsByClassName("conversation-window")[0].scrollTop = document.getElementsByClassName("conversation-window")[0].scrollHeight;
        }
    }

    function handleNewMsgFromUser() {
        const newusermsg = document.getElementsByClassName("chat-input")[0].value;
        if (newusermsg.trim() === "") {
            return;
        }
        handleNewMsg("user", newusermsg);
        fetchMetaDataApi(newusermsg);
        fetchDataStreamApi(newusermsg);
        setShowChat((prev) => false);
        if (userMessages.length > 2) {
            setTimeout(() => {
                document.getElementsByClassName("conversation-window")[0].scrollTop = document.getElementsByClassName("conversation-window")[0].scrollHeight;
            }, 100);
        }

        document.getElementsByClassName("chat-input")[0].value = "";
    }

    function handleToggle() {
        setShowChat((prev) => !prev);
    }

    function handleEnterPressed(e) {
        if (e.key === "Enter") {
            handleNewMsgFromUser();
            if (userMessages.length > 2) {
                setTimeout(() => {
                    document.getElementsByClassName("conversation-window")[0].scrollTop = document.getElementsByClassName("conversation-window")[0].scrollHeight;
                }, 100);
            }
        }
    }

    React.useEffect(() => {
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("option")) {
                const content = e.target.textContent;
                handleNewMsg("user", content);
                setShowChat((prev) => false);
                fetchMetaDataApi(content);
                fetchDataStreamApi(content);

            }
        });
    }, []);
    return (<div className="App">
        <header className="App-header container-lg">
            <ul className="nav">
                <li className="brand" style={{marginRight: "0"}}>
                    <a href="">
                        <img
                            style={{width: "77px", height: "75px"}}
                            src={logo}
                            alt="logo"
                        />
                    </a>
                </li>
                <li className="middle-text">
                    <h3>
                        <img src={brand} alt="brand"/>
                    </h3>
                </li>
                <div className="btns">
                    <li
                        className="reset-btn"
                        style={{display: showChat ? "none" : "inline"}}
                    >
                        <img
                            style={{marginRight: "5px"}}
                            src={refresh}
                            alt="refresh button"
                        />
                        <a onClick={resetAll} href="">
                            start over
                        </a>
                        <div className="reset-border"></div>
                    </li>
                </div>
            </ul>
        </header>
        <hr/>
        <main className={`container-lg ${showChat ? "" : "show-chat-around"}`}>
            <section className="chat-section">
                <div
                    style={{display: showChat ? "inline" : "none"}}
                    className="top-chat-area"
                >
                    <div className="green-and-text">
                        <div className="green-square"></div>
                        <p className="chat-header">
                            Hi, I’m Fabrice AI, trained with Fabrice Blogs. Ask a question
                            or start with an example.
                        </p>
                    </div>
                    <div className="options">
                        {prompts.map((q) => {
                            return (<div className={`option option-${q.id}`} key={q.id}>
                                {q.query}
                            </div>);
                        })}
                    </div>
                </div>

                <section
                    style={{
                        display: showChat ? "none" : "inline", overflowY: "scroll",
                    }}
                    className="conversation-window"
                >
                    {showConversation}
                    {fetchingApi && (<div className="app" key={0}>
                        <div className="green-square"></div>
                        <div className="msg-app">
                            <div id="animated-dots">
                                <div id="first-dot">.</div>
                                <div id="second-dot">.</div>
                                <div id="third-dot">.</div>
                            </div>
                        </div>
                    </div>)}
                </section>

                <div className="chat-box">
                    <input
                        disabled={!enalbeAsking}
                        onKeyUp={handleEnterPressed}
                        className="chat-input"
                        type="text"
                        placeholder="Enter a prompt..."
                    />
                    <button onClick={handleNewMsgFromUser} className="send-btn">
                        <img src={arrow} alt="arrow button"/>
                    </button>
                </div>

                {error && (<p className="error">Please check connection ({error.message})</p>)}

                <footer>
                    <p className="">
                        Fabrice AI is an experimental tool in Beta and may be inaccurate
                        at times.&nbsp;
                        <a
                            target="_blank"
                            href="https://dev.fabricegrinda.com/feedback-form/"
                        >
                            Your feedback
                        </a>
                        &nbsp; will help us refine it.{" "}
                        <a
                            target="_blank"
                            href="https://dev.fabricegrinda.com/disclaimer/"
                        >
                            Read Disclaimers.
                        </a>
                    </p>
                </footer>
            </section>

            <section className="gif-section container-lg">
                <div
                    className={`gif-section-1 row ${showSources ? "hide-gif-panel" : ""}`}
                >
                    <h3 className="col-12">Top Sources
                        &nbsp;
                        {sourcesLoading && (
                            <span style={{color: "gray", fontSize: "14px"}}>
                                (Loading sources...)
                            </span>
                        )}</h3>
                    <img className="col-12" src={netowrk} alt="image here"/>
                </div>

                <div
                    className={`gif-section-2 ${showSources ? "" : "hide-gif-panel"}`}
                >
                    <h3>
                        Top Sources &nbsp;
                        {(!sourcesLoading && sources.length) || unicornSources.length}
                        &nbsp;
                        {sourcesLoading && (
                            <span style={{color: "gray", fontSize: "14px"}}>
                                (Loading sources...)
                            </span>
                        )}
                    </h3>
                    {sources}
                    {showSources && unicornSources === 0 && newRasWourcesApi}
                    {unicornSources.length > 0 && (<div className="unicorn-data-section mt-5">{showUnicorn}</div>)}
                    {sourcesLoading &&
                        <>

                            <div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                                <Skeleton width={103} height={80}/>
                                <div style={{width: '100%'}}><Skeleton count={3}/></div>
                            </div>
                            <div className="horizontal-divider"></div>
                        </>
                    }
                </div>
            </section>
        </main>
    </div>);
}

export default App;

import {
    Button,
    Card,
    Col,
    Collapse,
    Container,
    Form,
    FormControl,
    Image,
    Nav,
    Navbar,
    NavbarToggle,
    NavDropdown,
    NavItem,
    Offcanvas,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import logo from "./assets/images/logo.png";
import brand from "./assets/images/fabrice-ai-logo.svg";
import sidebar from "./assets/images/sidebar-toggle.svg";
import newChat from "./assets/images/new-chat.svg";
import newChatDisabled from "./assets/images/new-chat-disabled.svg";
import React, {useEffect, useId, useLayoutEffect, useRef, useState} from "react";
import './AppFabrice.css';
import GrowShrinkTooltip from "./components/GrowShrinkTooltip";
import mic from './assets/images/mic.svg';
import send from './assets/images/send.svg';
import sendDisabled from './assets/images/send-disabled.svg';
import userChat from './assets/images/user-chat.svg';
import aiChat from './assets/images/ai-chat.svg';
import {categorizeAndGroupData, extractLastCompleteObject, parseMetadata, separateObjects} from "./utils/Utils";
import Skeleton from "react-loading-skeleton";
import placeholder from "./assets/images/placeholder.svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import DeviceUUID from 'device-uuid/lib/device-uuid';
import Markdown from "react-markdown";
import secureLocalStorage from "react-secure-storage";
import async from "async";
import {FaArrowsRotate, FaRotateLeft} from "react-icons/fa6";
import {createPortal} from "react-dom";
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import useCurrentHeight from "./hooks/useCurrentHeight";
import packageJson from "../package.json";
import {FaArrowAltCircleDown, FaArrowCircleDown} from "react-icons/fa";
import {IoArrowDownCircleOutline} from "react-icons/io5";
import scrollDown from './assets/images/scroll-down.svg';

function AppFabrice() {
    const data = [{
        period: "Today", items: ["How to make the most impor..."]
    }, {
        period: "Yesterday",
        items: ["How to make the most impor...", "What does FJ lab’s purpose...", "What is the biggest statement..."]
    }, {
        period: "Last week",
        items: ["How to make the most impor...", "What does FJ lab’s purpose...", "What is the biggest statement..."]
    }, {
        period: "This month",
        items: ["How to make the most impor...", "What does FJ lab’s purpose...", "What is the biggest statement..."]
    }, {
        period: "June",
        items: ["How to make the most impor...", "What does FJ lab’s purpose...", "What is the biggest statement..."]
    }];
    const defaultChat = {
        id: 1, createdAt: (new Date()).toISOString(), messages: [{
            byUser: false, message: '', topSources: []
        }]
    }
    const [selectedChat, setSelectedChat] = useState(1);
    const questions = ['What is FJ Labs\' purpose?', 'What does FJ Labs\' invest in?', 'Why is Fabrice optimistic about climate change?',
        'What are the biggest mistakes marketplace founders make?', 'How to make the most important decisions in your life?', 'How to live asset light?',
        'What are fair Seed & Series A valuations?', 'What is the key to happiness?'];
    const [prompt, setPrompt] = useState('');
    const promptTextAreaRef = useRef(null);
    const [chats, setChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const messagesScrollRef = useRef(null);
    const [contentLoading, setContentLoading] = useState(false);
    const [sourcesLoading, setSourcesLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('')
    const [showHistoryOffCanvas, setShowHistoryOffCanvas] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const [micPermission, setMicPermission] = useState('incomplete');
    const [audioLength, setAudioLength] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(new Uint8Array(0));
    const [recordedBlob, setRecordedBlob] = useState(null);
    const analysisRef = useRef(null);
    const audioRef = useRef(null);
    const dataStreamController = useRef(null);
    const metaDataController = useRef(null);
    const deviceId = new DeviceUUID.DeviceUUID().get();
    const speechRecognitionRef = useRef(null);
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState('100vh');
    const windowHeight = useCurrentHeight();
    const [showScrollIcon, setShowScrollIcon] = useState(false);

    /*useEffect(() => {
        const recognition = SpeechRecognition.getRecognition();
        recognition.addEventListener('end', () => {
            console.log('Speech recognition service has ended');
            SpeechRecognition.stopListening();
        });
    }, []);*/

    /*useEffect(() => {
        console.log('transcript', transcript);
        if (!listening && transcript.length > 0) {
            submitMessage(transcript);
            SpeechRecognition.stopListening();
            resetTranscript();
        }
    }, [listening, transcript]);*/

    useEffect(() => {
        localStorage.setItem('version', packageJson.version);
    }, [])

    useEffect(() => {
        console.log('containerHeight', containerHeight);
    }, [containerHeight]);

    useEffect(() => {
        const handlePermission = async () => {
            if (navigator.permissions) {
                const result = await navigator.permissions.query({name: 'microphone'});
                setMicPermission(result.state);
                result.onchange = () => {
                    setMicPermission(result.state);
                };
            }
        };
        handlePermission();

    }, []);

    const handleSpeech = () => {
        // setIsRecording(true);
        if (!browserSupportsSpeechRecognition) {
            alert('Speech Recognition is not supported in the current browser.');
            return;
        }
        // if (micPermission === 'denied') {
        //     alert("Microphone access has been denied. Please enable it in your browser settings.");
        //     return;
        // }
        SpeechRecognition.startListening({continuous: true});
        /*let speech;
        if (!('webkitSpeechRecognition' in window)) {
            alert('Speech Recognition is not supported in the current browser.');
            return;
        }
        if (!speechRecognitionRef.current) {

            const speechRecognition = new window.webkitSpeechRecognition();
            speechRecognition.continuous = false;
            speechRecognition.interimResults = false;
            speechRecognition.lang = 'en-US';
            speechRecognition.onstart = () => {
                setListeningState(true);
                console.log('started');
            };
            speechRecognition.onresult = (event) => {
                console.log('event', event);
                const transcript = event.results[0][0].transcript;
                setPrompt(prevState => {
                    speech = prevState + ' ' + transcript;
                    return speech;
                });
            };
            speechRecognition.onerror = (event) => {
                console.log(event.error);
            };
            speechRecognition.onspeechend = () => {
                setListeningState(false);
                console.log('ended', speech);
                if (speech && speech.length > 0) {
                    submitMessage(speech);
                    speech = '';
                    setPrompt(speech);
                }
            };
            speechRecognitionRef.current = speechRecognition;
        }
        if (listening) {
            speechRecognitionRef.current.stop();
        } else {
            speechRecognitionRef.current.start();
        }*/
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (listening) {
                setAudioLength(prevLength => prevLength + 1);
            }
        }, 200);

        return () => clearInterval(interval);
    }, [listening, micPermission]);

    const submitMessage = (message) => {
        setMessages(prevState => {
            const updatedMessages = prevState ? [...prevState, {
                byUser: true, message: message, topSources: []
            }, {
                byUser: false, message: '', topSources: [], status: 'incomplete'
            }] : [{
                byUser: true, message: message, topSources: []
            }, {
                byUser: false, message: '', topSources: [], status: 'incomplete'
            }];
            setChats(prevChats => {
                const updated = prevChats.map(item => {
                    return item.id === selectedChat ? {...item, messages: updatedMessages} : item
                });
                // console.log('updated', updated);
                localStorage.setItem('chatData', JSON.stringify({selected: selectedChat, chats: updated}));
                return updated;
            });
            return updatedMessages;
        });
        fetchApis(message);
        console.log("id", new DeviceUUID.DeviceUUID().get());
    };

    const fetchApis = (message) => {
        const currentChat = chats.find(v => v.id === selectedChat);
        const request = {
            query: message,
            deviceId: deviceId,
            tokenId: currentChat.tokenId ?? deviceId + '-' + Date.now(),
            threadId: currentChat.threadId
        };
        const resolveQuery = async () => {
            await Promise.all([fetchDataStreamApi(request),
                await fetchMetadataApi(request, async () => {
                    setMessages(prevState => {
                        const lastIndex = prevState.length - 1;
                        return [...prevState.slice(0, lastIndex), {
                            ...prevState[lastIndex], topSources: []
                        }];
                    });
                    await fetchMetadataApi(request);
                })])
        }
        setCurrentStatus('incomplete');
        resolveQuery().then(() => {
            setCurrentStatus(prevState => prevState === 'error' ? prevState : 'complete');
        }).catch((e) => {
            console.log("got error", e);
            setCurrentStatus('error');
        }).finally(() => {
            console.log('controllers', dataStreamController.current, metaDataController.current);
            if (!dataStreamController.current?.signal?.aborted && !metaDataController.current?.signal?.aborted) {
                setCurrentStatus(prevStatus => {
                    setSelectedChat(prevSelectedChat => {
                        setMessages(prevMessages => {
                            const lastIndex = prevMessages.length - 1;
                            const updatedMessages = [...prevMessages.slice(0, lastIndex), {
                                ...prevMessages[lastIndex], status: prevStatus
                            }];
                            // console.log('selectedChat', prevSelectedChat);
                            setChats(prevChats => {
                                const updated = prevChats.map(item => {
                                    console.log('mapItem', item);

                                    return item.id === prevSelectedChat ? {...item, messages: updatedMessages} : item
                                });
                                console.log('updated', updated);
                                localStorage.setItem('chatData', JSON.stringify({
                                    selected: prevSelectedChat,
                                    chats: updated
                                }));
                                return updated;
                            });
                            return updatedMessages;
                        });
                        return prevSelectedChat;
                    });
                    return prevStatus;
                })

            }
        });
    }

    const selectChat = (chatId) => {
        console.log('chatId', chatId);
        setShowScrollIcon(false);
        const currentChatMessages = chats.find(v => v.id === selectedChat)?.messages;
        dataStreamController.current?.abort();
        metaDataController.current?.abort();
        setContentLoading(false);
        setSourcesLoading(false);
        const selectChatMessages = chats.find(v => v.id === chatId)?.messages;
        setSelectedChat(chatId);
        setMessages(selectChatMessages);
        // if (selectChatMessages?.length > 0 && selectChatMessages[selectChatMessages.length - 1].status === 'incomplete') {
        //     fetchApis(selectChatMessages[selectChatMessages.length - 2].message);
        // }
    };

    const startNewChat = () => {
        setShowScrollIcon(false);
        setChats(prevState => {
            const currentChatMessages = chats.find(v => v.id === selectedChat)?.messages ?? [];
            dataStreamController.current?.abort();
            metaDataController.current?.abort();
            setContentLoading(false);
            setSourcesLoading(false);
            setSelectedChat(prevState.length + 1);
            const updatedChat = [...prevState, {...defaultChat, id: prevState.length + 1, messages: []}];
            console.log('updatedChatNew', updatedChat);
            localStorage.setItem('chatData', JSON.stringify({
                selected: prevState.length + 1,
                chats: updatedChat
            }));
            return updatedChat;
        });
        setMessages([]);
    };

    const fetchDataStreamApi = async (query) => {
        let updatedMessages;
        try {
            setContentLoading(true);
            dataStreamController.current = new AbortController();
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stream`, {
                signal: dataStreamController.current?.signal,
                method: "POST", headers: {
                    "Content-Type": "application/json",
                }, body: JSON.stringify(query),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            while (true) {
                const {done, value} = await reader.read();
                if (done || dataStreamController.current?.aborted) break;
                const chunk = decoder.decode(value, {stream: true});
                const objects = separateObjects(chunk);
                const threadId = objects[0].threadId;
                const message = objects.map(value => value.response).join('');
                setMessages(prevState => {
                    const lastIndex = prevState.length - 1;
                    updatedMessages = [...prevState.slice(0, lastIndex), {
                        ...prevState[lastIndex], message: prevState[lastIndex].message + message
                    }];
                    setChats(prevChats => {
                        const updatedChats = prevChats.map(item => {
                            // console.log('mapItem', item);
                            return item.id === selectedChat ? {
                                ...item,
                                messages: updatedMessages,
                                threadId: threadId ? threadId : item.threadId,
                                tokenId: query.tokenId
                            } : item
                        });
                        // console.log('updated', updatedChats);
                        localStorage.setItem('chatData', JSON.stringify({
                            selected: selectedChat,
                            chats: updatedChats
                        }));
                        return updatedChats;
                    });
                    return updatedMessages;
                });
            }
        } catch (e) {
            console.log(e);
            if (e instanceof TypeError) setCurrentStatus('error');
        } finally {
            setContentLoading(false);
            // console.log('updated messages', messages);
            /*if (updatedMessages && updatedMessages[updatedMessages.length - 1].message.search('do not have that information available') === -1) {
                await fetchMetadataApi({
                    ...query, message: `Answer - "${updatedMessages[updatedMessages.length - 1].message}"
    Give the relevant sources to the answer above. Share the exact json object while performing tool - file search. You can add additional sources related to answer.`
                }, async () => {
                    setMessages(prevState => {
                        const lastIndex = prevState.length - 1;
                        return [...prevState.slice(0, lastIndex), {
                            ...prevState[lastIndex], topSources: []
                        }];
                    });
                    await fetchMetadataApi({
                        ...query, message: `${updatedMessages[updatedMessages.length - 1].message}
    Please share valid relevant sources for the query, do not generate fake links and json objects.`
                    })
                });
            }*/
        }
    }

    const fetchMetadataApi = async (query, refetch) => {
        setSourcesLoading(true);
        let buffer = '';
        try {
            metaDataController.current = new AbortController();
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/metadataStream`, {
                signal: metaDataController.current?.signal,
                method: "POST", headers: {
                    "Content-Type": "application/json",
                }, body: JSON.stringify(query), // body data type must match "Content-Type" header
            });
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            let lastCompleteObjects = [];

            while (true) {
                const {done, value} = await reader.read();
                if (done || metaDataController.current?.aborted) break;
                const chunk = decoder.decode(value, {stream: true});
                buffer += separateObjects(chunk).filter(v => v.response !== '```' && v.response !== 'json').map(value => value.response).join('');
                const lastObjects = extractLastCompleteObject(buffer);
                if (lastObjects && lastObjects.length > 0 && !lastCompleteObjects.some(value1 => lastObjects[lastObjects.length - 1]?.link === value1.link)) {
                    console.log('extractLastCompleteObject', lastObjects[lastObjects.length - 1]);
                    if (lastObjects[lastObjects.length - 1]?.link.search('example.com') !== -1 && refetch) {
                        refetch();
                        break;
                    }
                    const parsedMetadata = await parseMetadata([lastObjects[lastObjects.length - 1]]);
                    setMessages(prevState => {
                        const lastIndex = prevState.length - 1;
                        const updatedMessages = [...prevState.slice(0, lastIndex), {
                            ...prevState[lastIndex], topSources: [...prevState[lastIndex].topSources, parsedMetadata]
                        }];
                        setChats(prevChats => {
                            const updatedChats = prevChats.map(item => {
                                return item.id === selectedChat ? {...item, messages: updatedMessages} : item
                            });
                            localStorage.setItem('chatData', JSON.stringify({
                                selected: selectedChat,
                                chats: updatedChats
                            }));
                            return updatedChats;
                        });
                        return updatedMessages;
                    });
                    lastCompleteObjects = [...lastCompleteObjects, lastObjects[lastObjects.length - 1]];
                }
            }
        } catch (e) {
            if (e instanceof TypeError) setCurrentStatus('error');
        } finally {
            setSourcesLoading(false);
            console.log('buffer', buffer);
        }
    }

    useEffect(() => {
        const item = localStorage.getItem('chatData');
        // console.log('item', item);
        if (item) {
            const chatData = JSON.parse(item.toString());
            const currentMessages = chatData.chats.find(v => v.id === chatData.selected)?.messages ?? [];
            // console.log('defaultChat', chats);
            setChats(chatData.chats);
            setSelectedChat(chatData.selected);
            setMessages(currentMessages);
            // if (currentMessages.length > 0 && currentMessages[currentMessages.length - 1].status === 'incomplete') {
            //     fetchApis(currentMessages[currentMessages.length - 2].message);
            // }
        } else {
            // console.log('defaultChat', categorizeAndGroupData(defaultChat));
            setChats([defaultChat]);
        }
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const {scrollTop, scrollHeight, clientHeight} = messagesScrollRef.current;
            if (scrollTop + clientHeight >= scrollHeight) {
                setShowScrollIcon(false);
            } else {
                setShowScrollIcon(true);
            }
        };

        const divElement = messagesScrollRef.current;
        divElement.addEventListener('scroll', handleScroll);

        return () => {
            divElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (messagesScrollRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = messagesScrollRef.current;
            console.log('scrollTop', scrollTop, 'clientHeight', clientHeight, 'scrollTop + clientHeight',
                scrollTop + clientHeight, 'scrollHeight', scrollHeight);
            if (scrollTop + clientHeight >= scrollHeight) {
                setShowScrollIcon(false);
            } else {
                if (contentLoading && messages[messages.length - 1].message.length <= 400) {
                    messagesScrollRef.current.scrollTop = messagesScrollRef.current.scrollHeight;
                } else setShowScrollIcon(true);
            }
        }
        // console.info('messages', messages, messagesScrollRef.current.scrollTop, messagesScrollRef.current.scrollHeight);
    }, [messages]);

    useLayoutEffect(() => {
        if (promptTextAreaRef.current) {
            promptTextAreaRef.current.style.height = 'inherit';
            promptTextAreaRef.current.style.height = `${Math.min(promptTextAreaRef.current.scrollHeight, 6 * 24)}px`;
        }
    }, [prompt]);

    useEffect(() => {
        console.log('height', window.innerHeight);
        setContainerHeight(window.innerHeight.toString() + 'px');
    }, [containerRef.current]);

    return (<Container fluid className={'p-0'} ref={containerRef}>
        <Navbar variant="dark" expand="lg">
            <Container className="py-2" style={{maxWidth: '1000px'}}>
                <Navbar.Toggle aria-controls="history-off-canvas" onClick={() => setShowHistoryOffCanvas(true)}>
                    <Image style={{transform: 'rotateY(180deg)'}} src={sidebar} alt="open-sidebar"/>
                </Navbar.Toggle>
                <Navbar.Brand className="mx-auto mx-md-0" href="https://fabricegrinda.com/">
                    <Image style={{width: 60, height: 60}} src={logo} alt="logo"/>
                </Navbar.Brand>
                <Navbar.Offcanvas
                    id="history-off-canvas"
                    className="px-2 py-4 d-lg-none"
                    placement="start"
                    show={showHistoryOffCanvas}
                    onHide={() => setShowHistoryOffCanvas(false)}
                >
                    <Offcanvas.Header>
                        <Button variant="link" onClick={() => setShowHistoryOffCanvas(false)}>
                            <Image src={sidebar} alt="close-sidebar"/>
                        </Button>
                        <Button variant="link" disabled={messages.length === 0} className="ms-auto" onClick={() => {
                            startNewChat();
                            setShowHistoryOffCanvas(false);
                        }}>
                            <Image src={chats[chats.length - 1]?.messages.length > 0 ? newChat : newChatDisabled}
                                   alt="new-chat-alt"/>
                        </Button>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={'d-flex flex-column'}>
                        <Nav className="pe-3 ">
                            {Object.entries(categorizeAndGroupData(chats)).reverse().map(([group, items], index) => (
                                <React.Fragment key={index}>
                                    <Nav.Item>
                                        <h6 className="text-black-50">{group}</h6>
                                    </Nav.Item>
                                    {items.reverse().map((item, index1) => (<Nav.Item key={`${index}-${index1}`}>
                                        <p
                                            className={`cursor-pointer ${selectedChat === item.id ? 'bg-secondary bg-opacity-25 rounded-2 p-2' : ''}`}
                                            style={{
                                                width: '100%',
                                                overflowX: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                            onClick={() => {
                                                selectChat(item.id);
                                                setShowHistoryOffCanvas(false);
                                            }}>{item.messages?.[0]?.message || 'New Chat'}</p>
                                    </Nav.Item>))}
                                </React.Fragment>))
                            }
                        </Nav>

                    </Offcanvas.Body>
                    {/*<div className={'sticky-bottom w-100 text-center'}><small>v{packageJson.version}</small></div>*/}
                </Navbar.Offcanvas>
                <Button className="d-lg-none " disabled={messages.length === 0} variant={"link"} onClick={() => {
                    startNewChat();
                }}><Image src={chats && chats[chats.length - 1]?.messages.length > 0 ? newChat : newChatDisabled}
                          alt="brand"/></Button>
                <Image className="d-none d-lg-block mx-auto" style={{width: 200, height: 46}} src={brand} alt="brand"/>
                {/*<h4 className="d-none d-lg-block">
                    <a className="link-dark link-underline-opacity-0" target="_blank"
                       href="https://fabricegrinda.com/my-blog-is-now-multilingual/">
                        Fabrice Blogs
                    </a>
                </h4>*/}
            </Container>
        </Navbar>
        <hr className={'m-0'}/>
        <Container style={{maxWidth: '1000px'}}>
            <Row className={'justify-content-center'}>
                <Col lg={'auto'} className={'d-none d-lg-block '}>
                    <Row className={'py-3 flex-grow-0'}>
                        <Col lg={6} className={'cursor-pointer'}><GrowShrinkTooltip
                            message={'Open sidebar'}>
                            <Image className={`open-sidebar ${isSidebarCollapsed ? 'flip' : ''}`} src={sidebar}
                                   alt={'open-sidebar'} onClick={() => {
                                setSidebarCollapsed(!isSidebarCollapsed);
                            }}/>
                        </GrowShrinkTooltip></Col>
                        <Col lg={6} className={'cursor-pointer text-end pe-5'}>
                            <GrowShrinkTooltip
                                message={'New Chat'}
                            >
                                <Image
                                    src={chats && chats[chats.length - 1]?.messages.length > 0 ? newChat : newChatDisabled}
                                    alt={'new-chat'}
                                    onClick={() => {
                                        if (messages.length > 0) startNewChat();
                                    }}/>
                            </GrowShrinkTooltip>
                        </Col>
                    </Row>
                    <Col id={`custom-scrollbar`}
                         className={`flex-grow-1 collapse-width position-relative ${isSidebarCollapsed ? 'hide' : ''}`}>
                        {Object.entries(categorizeAndGroupData(chats)).reverse().map(([group, items], index) => (
                            <div key={index} >
                                <h6 className={'text-black-50'}>{group}</h6>
                                {items?.reverse().map((item, index1) => (
                                    <div key={`${index}-${index1}`} className={'pe-3 pb-3'}><p
                                        className={`cursor-pointer ${selectedChat === item.id ? 'bg-secondary bg-opacity-25 rounded-2 p-2' : ''}`}
                                        style={{
                                            width: '100%',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                        onClick={() => {
                                            selectChat(item.id);
                                        }}>{item.messages?.[0]?.message || 'New Chat'}</p>
                                    </div>))}
                            </div>))
                        }

                    </Col>
                    {/*<Row className={'flex-grow-0 w-100 position-sticky bottom-0 text-center'}><small>v{packageJson.version}</small></Row>*/}
                </Col>

                <Col style={{
                    height: `calc(${windowHeight} - 7rem)`, display: 'flex', flexDirection: 'column',
                    overflow: 'hidden', position: 'relative'
                }}
                    // style={{height: ''}}
                >
                    {showScrollIcon &&
                        <div style={{
                            bottom: '10.5rem',
                            backgroundColor: 'transparent',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'absolute',
                            overflow: "hidden",
                            right: '0.20rem',
                            zIndex: '99999'
                        }}><Image className={'cursor-pointer'}
                                  style={{
                                      borderRadius: '50%'
                                  }}
                                  src={scrollDown} fill="#212529" width={32} height={32} onClick={() => {
                            messagesScrollRef.current.scrollTop = messagesScrollRef.current.scrollHeight;
                        }}/></div>
                    }
                    <Row ref={messagesScrollRef} style={{flex: '1 1 auto', overflowY: 'auto', overflowX: 'hidden',}}
                         className={'mt-4'}>
                        {messages?.length === 0 ?
                            <Col className={'px-4'}
                                 lg={12} xl={11}>
                                <Row className={'justify-content-center'}>
                                    {questions.map((value, index) => (<Col key={index} xs={6} sm={6} md={6} lg={'auto'}>
                                        <Card
                                            className='query-card mx-auto rounded-4 cursor-pointer shadow-sm p-2 mb-4 text-secondary'
                                            onClick={() => {
                                                submitMessage(value);
                                            }}>
                                            <div className={'query-card-body'}>{value}</div>
                                        </Card>
                                    </Col>))}
                                </Row></Col> :
                            <Col style={{overflowX: 'hidden'}}>{messages.map((value, index) => {
                                const isLastIndex = index === messages.length - 1;
                                const showTopSources = (!isLastIndex && value.topSources.length > 0) || (isLastIndex && (sourcesLoading || value.topSources.length > 0));
                                return (<Row key={`message-${index}`} className={'mt-3 ps-3 pe-3'}>
                                    <Col className={'pe-0'} xs={'auto'}>
                                        <Image
                                            src={value.byUser ? userChat : aiChat}/></Col>
                                    <Col className={'pe-0 text-justify'}>
                                        {value.byUser ? value.message : value.message.length === 0 && (contentLoading || sourcesLoading) ?
                                            <div className="bouncing-dots">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div> : <Markdown>{value.message}</Markdown>}
                                        {!value.byUser && (!contentLoading || !isLastIndex) && <>
                                            {showTopSources && <Row className={'fs-5 mt-4'}><Col><strong>Top
                                                Sources</strong></Col></Row>}
                                            <Row className={'mt-3'}>{value.topSources.sort((a, b) =>
                                                new Date(b.date) - new Date(a.date)
                                            ).map((source, index) => (
                                                <Col key={`source-${index}`}
                                                     className={'mb-3 align-items-center justify-content-center'}
                                                     xs={6} sm={4} md={3} lg={4}>
                                                    <a
                                                        target={'_blank'}
                                                        href={source.link}
                                                        className={"link-dark link-underline-opacity-0 d-flex flex-column align-items-center align-items-md-center h-100"}
                                                    >
                                                        <LazyLoadImage
                                                            style={{
                                                                width: '100%',
                                                                height: '100px',
                                                                borderRadius: '10px',
                                                                objectFit: 'cover'
                                                            }}
                                                            alt={"placeholder"}
                                                            // effect={'blur'}
                                                            src={source.img ?? placeholder}/>
                                                        <p className={'mt-2 text-start'}>{source.title}</p>
                                                    </a>
                                                </Col>))}
                                                {index === messages.length - 1 && sourcesLoading &&
                                                    <Col className={'mb-3 align-items-center justify-content-center'}
                                                         xs={6} sm={4} md={3} lg={4}>
                                                        <Row className={'align-items-center'}>
                                                            <Col>
                                                                <Skeleton
                                                                    width={'100%'}
                                                                    height={'100px'}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className={'mt-2'}><Col><Skeleton
                                                            count={1}/></Col></Row>
                                                    </Col>}
                                            </Row>
                                        </>}
                                        {!value.byUser && !contentLoading && !sourcesLoading && value.status !== 'complete' &&
                                            <>
                                                <p
                                                    className={`cursor-pointer bg-opacity-25 border border-1 ${value.status === 'error' ?
                                                        'bg-danger border-danger' : 'bg-secondary-subtle border-secondary-subtle'} rounded-2 p-2 text-black-50`}
                                                    style={{
                                                        width: 'fit-content',
                                                        userSelect: 'none',
                                                        marginLeft: 'auto'
                                                    }}
                                                    onClick={() => {
                                                        const lastIndex = messages.length - 1;
                                                        const updatedMessages = [...messages.slice(0, lastIndex), {
                                                            ...messages[lastIndex],
                                                            message: '',
                                                            topSources: [],
                                                            status: 'incomplete'
                                                        }];
                                                        setMessages(updatedMessages);
                                                        fetchApis(updatedMessages[lastIndex - 1].message);
                                                    }}
                                                >{value.status === 'error' ? 'Oops, something went wrong. Please try again.' : 'Continue generating'}
                                                    <FaArrowsRotate className={'ms-2'} size={18}
                                                                    style={{background: 'transparent', color: 'wheat'}}
                                                    /></p>

                                            </>
                                        }
                                    </Col>
                                </Row>)
                            })}</Col>}
                    </Row>
                    {(messages?.[messages?.length - 1]?.status !== 'incomplete' || (contentLoading || sourcesLoading)) &&
                        <Row style={{flex: '0 0 auto'}} className={'px-3 py-2'}>
                            <Col lg={12} className={'mb-4 mb-md-0'}>
                                {/*{showScrollIcon && <Row className={'pb-2 pt-0'} style={{backgroundColor: 'mediumvioletred'}}>
                                    <Image className={'cursor-pointer'} src={scrollDown} fill="#212529" width={28} height={28} onClick={() => {
                                        messagesScrollRef.current.scrollTop = messagesScrollRef.current.scrollHeight;
                                    }}/>
                                </Row>
                                }*/}
                                {listening ? <Row>
                                        <div
                                            className={'d-flex align-items-center rounded-4 border border-1 border-secondary-subtle py-3 px-2'}>
                                            <AiFillCloseCircle style={{fontSize: '24px'}} onClick={() => {
                                                SpeechRecognition.stopListening();
                                                resetTranscript();
                                                setAudioLength(0);
                                            }}/>
                                            <div
                                                className={'px-1 d-flex flex-grow-1 overflow-x-auto align-items-center justify-content-end'}>
                                                {
                                                    Array.from({length: 20}).map((value, index) =>
                                                        <div key={index} style={{marginRight: '1px'}}>
                                                            <div style={{
                                                                width: '2px',
                                                                height: `6px`,
                                                                backgroundColor: 'gray',
                                                            }}></div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    transcript.split(' ').map((value, index) =>
                                                        <div key={`transcript-${index}`} style={{marginRight: '1px'}}>
                                                            <div style={{
                                                                width: '2px',
                                                                height: `${value.length * 3}px`,
                                                                backgroundColor: 'blue',
                                                            }}></div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    Array.from({length: audioLength}).map((value, index) =>
                                                        <div key={`mimic-${index}`} style={{marginRight: '1px'}}>
                                                            <div style={{
                                                                width: '2px',
                                                                height: `24px`,
                                                                backgroundColor: 'blue',
                                                            }}></div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <AiFillCheckCircle style={{fontSize: '24px'}} onClick={() => {
                                                setPrompt(transcript);
                                                SpeechRecognition.abortListening();
                                                resetTranscript();
                                                setAudioLength(0);
                                            }}/>

                                        </div>
                                    </Row> :
                                    <>
                                        <Row>
                                            <Form
                                                className={'d-flex rounded-4 border border-1 border-secondary-subtle py-2 px-1'}
                                                noValidate={true}
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    const form = event.currentTarget;
                                                    if (!sourcesLoading && prompt.length > 0) {
                                                        submitMessage(prompt);
                                                        setPrompt('');
                                                    }
                                                }}
                                            >
                                                <Col>

                                                    <FormControl
                                                        as={'textarea'}
                                                        ref={promptTextAreaRef}
                                                        rows={1}
                                                        required={true}
                                                        disabled={contentLoading || sourcesLoading}
                                                        className={'no-border'}
                                                        placeholder={'Enter a prompt'}
                                                        value={prompt}
                                                        onChange={(e) => {
                                                            setPrompt(e.target.value)
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                if (event.shiftKey) {

                                                                } else {
                                                                    event.preventDefault();
                                                                    if (prompt.length > 0) {
                                                                        submitMessage(prompt);
                                                                        setPrompt('');
                                                                    }

                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={"auto"} className={'p-0 align-self-center'}><GrowShrinkTooltip
                                                    message={'Use microphone'}>
                                                    <Image
                                                        className={`cursor-pointer ${listening ? 'glowing' : ''}`}
                                                        src={mic}
                                                        alt={'Speak into the mic'}
                                                        onClick={handleSpeech}
                                                    /></GrowShrinkTooltip></Col>
                                                <Col xs={"auto"}>
                                                    <div className={'vr ms-3'}/>
                                                </Col>
                                                <Col xs={"auto"} className={'ps-0 align-self-center cursor-pointer'}>
                                                    <Button type={'submit'} variant={'link'}><Image
                                                        aria-disabled={sourcesLoading || prompt.trim().length === 0}
                                                        src={sourcesLoading || prompt.trim().length === 0 ? sendDisabled : send}
                                                    /></Button>
                                                </Col>
                                            </Form>
                                        </Row>
                                        <Row className={'mt-2'}><small>Fabrice AI is an experimental tool and may be
                                            inaccurate at times.
                                            {' '}
                                            {/*Your <a href={`${process.env.REACT_APP_FEEDBACK_URL}`}
                                                    target={'_blank'}>feedback</a> will help us refine it.*/}
                                            Read <a href={`${process.env.REACT_APP_DISCLAIMER_URL}`}
                                                    target={'_blank'}>Disclaimers</a></small></Row>
                                    </>
                                }
                            </Col>
                        </Row>}
                </Col>


            </Row>
        </Container>
    </Container>);
}

export default AppFabrice;